
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { VueCropper } from 'vue-cropper';

@Component({
  components: {
    VueCropper,
  },
})
export default class ImageCropper extends Vue {
  @Prop({ type: [String, File], default: '' }) readonly img!: string | File;
  @Prop({ type: Boolean, default: false }) readonly isShow!: boolean;
  @Prop({ type: String, default: '' }) operate!: string; // 判断是否启用某些功能

  visible: boolean = false;
  fileData: string | Blob = '';
  outputType: string = 'png';

  get isImgFile(): boolean {
    return Object.prototype.toString.call(this.img) === '[object File]';
  }
  created(){
  console.log(this.operate)
}

  @Watch('img')
  onImgChange(newVal: string | File) {
    if (typeof newVal === 'string') {
      this.fileData = newVal;
      this.visible = true;
      return;
    }
    if (this.isImgFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileData = e.target.result;
        this.visible = true;
      };
      reader.readAsDataURL(newVal);
    }
  }

  crop() {
    (this.$refs.cropper as any).getCropBlob((data: Blob) => {
      const img: File = this.img as File;
      const isImgFile: boolean = this.isImgFile;
      const now = new Date();
      const file = new File([data], isImgFile ? img.name : `cropped-image.${this.outputType}`, {
        lastModified: +now,
      });
      this.$emit('crop', file);
      this.visible = false;
    });
  }

  cancel() {
    this.$emit('crop', false);
    this.visible = false;
  }
  cancelModal(){
    this.visible = false;
  }
}
